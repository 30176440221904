/* fonts impot */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --gray_bg: #F9F9F9;
    --black: #000;
    --white: #fff;
    --secondary_black: #3F3E3E;
    --input_border: #282828;
    --placeholder: #959595;
    --navigation: #0081F9;
    --dashboard_bg : #F0F6FF;
    --news_input : #E4E4E4;
    --timer : #8A8A8A;
    --green : #14A44D;
    --red : #DC4C64;
    --bootstrap_red : #dc3545;
    --bootstrap_green : #198754;
    --slide_border : #00509B;
    --img_uploader : #BCBCBC;
    --slider_bg : #D9D9D9;
    --lower_font: 8px;
    --small_font: 10px;
    --font_twelve: 12px;
    --font_thirteen: 13px;
    --font_fourteen: 14px;
    --font_fifteen: 15px;
    --font_sixtee: 16px;
    --font_eighteen: 18px;
    --font_twenty: 20px;
    --font_twentyFour: 24px;
    --highter_font: 25px;
    --font_thirty : 30px;
    --weight_four: 400;
    --weight_five: 500;
    --weight_six: 600;
    --weight_seven: 700;
    --weight_eight: 800;
    --weight_nine: 900;
    --form_label_color: #959595;

    /* Focus ring */
    --bs-focus-ring-color: rgb(255, 255, 255, 0);
}

/* common css */
* {
    font-family: "Montserrat", sans-serif;
}

.montserrat {
    font-family: "Montserrat", sans-serif;
}

.cmn__bgAligner {
    overflow-x: hidden;
}

.pointer {
    cursor: pointer !important;
}

.fs-8 {
    font-size: var(--lower_font);
}

.fs-10 {
    font-size: var(--small_font);
}

.fs-12 {
    font-size: var(--font_twelve);
}

.fs-13 {
    font-size: var(--font_thirteen);
}

.fs-14 {
    font-size: var(--font_fourteen);
}

.fs-15 {
    font-size: var(--font_fifteen);
}

.fs-16 {
    font-size: var(--font_sixtee);
}

.fs-18 {
    font-size: var(--font_eighteen);
}

.fs-20 {
    font-size: var(--font_twenty);
}

.fs-24 {
    font-size: var(--font_twentyFour);
}

.fs-25 {
    font-size: var(--highter_font);
}

.fw-100 {
    font-weight: 100;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}
/* number input button hider */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
button:disabled{
    cursor: not-allowed;
}
.common__inner{
    background-color: var(--dashboard_bg);
    min-height: 100vh;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

/* scrollbar css */
::-webkit-scrollbar {
    width:5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--navigation);
    border-radius: 10px;
}

/* switch css */
.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
/* end of switch css */
/* end of scrollbar css */

.whiteSpace__noWrapper{
    white-space: nowrap !important;
}
.cmn__navlink{
    color: unset;
    text-decoration: none;
}
.cmn__errorHint{
   color: var(--red);
   font-size: var(--font_thirteen);
   font-weight: var(--weight_five); 
   margin-top: 5px !important;
}
.cmn__modalTitle{
    color: var(--black);
    font-size: var(--font_eighteen);
    font-weight: var(--weight_six);
}
.cmn__inputLabel{
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
}
/* end of common css */

/* karthick css */
/* sigin css */
.signIn__commonBg {
    width: 100%;
    height: 100vh;
    background-image: url('../Images/background.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;

}

.signIn__logo {
    width: 200px;
    height: 100px;
    /* margin: 50px; */
}

.singIn__formTitle {
    color: var(--secondary_black);
    font-size: var(--font_twenty);
    font-weight: var(--weight_seven);

}

.signIn__formHolder {
    width: fit-content;
    min-width: 50%;
    background-color: var(--gray_bg);
    box-shadow: 0px 0px 12px 2px lightgray;
    max-height: 80vh;
    overflow-y: auto;
}

.cmn__inputHolder {
    border: 1px solid var(--input_border);
}

.signIn__inputter {
    border: none;
    outline: none;
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_four);
    background-color: transparent;
    min-width: 100%;
    max-width: 100%;
}
.signIn__widthInputter{
    min-width: 250px !important;
}

.signIn__inputter::placeholder {
    color: var(--placeholder);
}
.signIn__inputter.signIn__dateInput{
    min-width: 100%;
}

.singIn__forgotPw {
    color: var(--navigation);
    font-size: var(--font_twelve);
    font-weight: var(--weight_six);
}

.primary__btn {
    border: 1px solid var(--navigation);
    outline: none;
    background-color: var(--navigation);
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    padding: 5px 40px;
    width: fit-content;
}
.primary__btn:hover{
    background-color: transparent;
    color: var(--navigation);
    border-color: var(--navigation);
}
button:disabled{
    background-color: var(--placeholder);
    cursor: not-allowed;
    color: var(--black);
    border-color: var(--placeholder);
}

.signIn__eye {
    position: absolute;
    top: 30%;
    right: 5%;
}

/* end of signin css */

/* karthick add student css */
.studDtls__placeholder{
    color: var(--placeholder);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_four);
    padding: 3px ;
}
.studDtls__phoneInpHolder input.form-control,.studDtls__phoneInpHolder .react-tel-input .selected-flag,.studDtls__phoneInpHolder .react-tel-input .flag-dropdown{
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
}
.studDtls__selectHolder{
    top: -2px;
}
button.addStdt__backBtn:disabled{
    background-color: transparent !important;
}
.addStdt__backBtn{
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--black);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_six);
    position: absolute;
    left: 5%;
    top: 5%;
}
.addStdt__backBtn svg{
    height: 30px;
    width: 30px;
}
.alert__title{
    color: var(--secondary_black);
    font-size: var(--highter_font);
    font-weight: var(--weight_four);
    line-height: 1.6;
}
.alert__hint{
    font-size: var(--font_fifteen);
    font-weight: var(--weight_four);
}
.addStdt__singleStep{
    border: 1px solid var(--navigation);
    outline: none;
    background-color: transparent;
    padding: 3px 30px;
}
.addStdt__singleStep.active{
    background-color: var(--navigation);
}
/* end of karthick add student css */

/* otp verification css */
.otpVerify__hint {
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
}

.otpVerify__hint span {
    font-size: var(--font_fifteen);
    font-weight: var(--weight_six);
}

.cmn__otpInpHolder input {
    height: 35px;
    width: 35px !important;
    border: 1px solid var(--input_border);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
    color: var(--black);
    outline: none;
    margin-right: 10px;

}

.otpVerify__plainbutton {
    outline: none;
    border: none;
    background-color: transparent;
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
    padding: 5px 30px;
}
.otpVerify__plainbutton:hover{
    color: var(--bootstrap_red);
}
.otpVerify__only.otpVerify__plainbutton:disabled{
    background-color: transparent !important;
}
.otpVerify__plainBluebutton {
    outline: none;
    border: none;
    padding: 6px 40px;
    background-color: transparent;
    color: var(--navigation);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
}
.otpVerify__plainBluebutton:hover{
    background-color: var(--navigation);
    color: var(--white);
}

.cmn__otpInpHolder span {
    display: none;
}

.otpVerify__countdown {
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
}

/* end of otp verification css */

/* header css */
.header{
    background-color: var(--black);
}
.header__logo{
    height: 50px;
    width: 100px;
}
.header__label{
    color: var(--white);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_six);
}
.header__burger{
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--white);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
}
.header__canvas{
    background-color: var(--black);
    max-width: 130px;
}
/* end of header css */

/* sidebar css */
.sidebar{
    background-color: var(--black);
    max-height: 87vh;
}
.sidebar__singleNav:hover .sidebar__label{
    opacity: 1;
}
.sidebar__label{
    color: var(--white);
    font-size: var(--lower_font);
    font-weight: var(--weight_five);
   opacity: 0;
}
.sidebar__notifier{
    color: var(--white);
    font-size: var(--highter_font);
    font-weight: var(--weight_seven);
}
.sidebar__excelBtn{
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--white);
    font-size: var(--font_eighteen);
    font-weight: var(--weight_five);
}
/* end of sidebar css */

/* dashboard css */
.dash__fileBtn{
    border: none;
    outline: none;
    padding: 5px 8px;
    background-color: var(--placeholder);
    color: var(--black);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
}
.dash__fileInp{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    min-width: 100%;
    max-width: 100%;
}
.dash__fileUploader{
    border: 1px solid var(--input_border);
    max-width: 300px;
    min-width: 300px;
}
.dash__filterDrop button.dropdown-toggle{
    border: none;
    outline: none;
    box-shadow: none;
    background-color: var(--navigation);
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    padding: 5px 20px;
    border-radius: 5px;
}
.dash__filterDrop button::after{
    display: none;
}
.dash__searchBtn{
    border: none;
        outline: none;
        box-shadow: none;
    background-color: var(--navigation);
        color: var(--white);
        font-size: var(--font_thirteen);
        font-weight: var(--weight_five);
        padding: 5px 10px;
        border-radius: 3px;
}
.dash__searchFilter{
    border: none;
    outline: none;
    background-color: transparent;
    max-width: 100%;
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_four);
}
.dash__searcherHolder{
    border: 1px solid var(--input_border);
    width: fit-content;
}
.dash__newBatch{
    border: none;
    outline: none;
    background-image:linear-gradient(to right,#1B68FA,#0061BC);
    color: var(--white);
    font-size: var(--font_twelve);
        font-weight: var(--weight_five);
}
.exchange_table_values{
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
}
.dash__tableHolder .asrt-table-body table thead tr th{
    background-color: var(--black) !important;
    color: var(--white) !important;
    font-size: var(--font_fifteen) !important;
    font-weight: var(--weight_five) !important;
    padding: 12px 0px !important;

}
.dash__tableHolder .asrt-table-foot{
    font-size: var(--font_thirteen) !important;
    font-weight: var(--weight_five) !important;
}
.dash__tableHolder .float-right{
    display: flex;
    justify-content: flex-end;
}
.dash__tableHolder .float-right input{
    border: 1px solid var(--input_border);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    box-shadow: none !important;
}
.dash__tableHolder .asrt-page-length .input-group-text{
    background-color: transparent !important;
}
.dash__tableHolder .asrt-page-length .input-group-addon{
    background: transparent !important;
}

.dash__tableHolder .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
    box-shadow: none;
    background: transparent !important;
    text-align: center;
}
.dash__tableDrop button,.dash__tableDrop button:hover,
.dash__tableDrop button:focus,
.dash__tableDrop button:focus-visible,
.dash__tableDrop button:active{
    border: none  !important;;
    outline: none  !important;
    background-color: transparent  !important;
    font-size: var(--font_thirteen)  !important;
    color: var(--black)  !important;
    box-shadow: none  !important;
}
.dash__tableDrop button::after{
    display: none;
}
.table-striped>tbody>tr:nth-of-type(odd)>*{
    background: #fff !important;
}
.table-striped>tbody>tr:nth-of-type(even)>*{
background:transparent;
}
.dash__tableDropItem{
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
}
.dash__tableDropItem:hover{
    background-color: var(--navigation);
    color: var(--white);
}
.dash__tableHolder .asrt-table-body{
    overflow: auto !important;
}
.dash__filtersHlder{
    overflow-x: auto;
    max-width: 100%;
}
/* end of dashboard css */

/* news css */
.new__titleBox{
    background-color: var(--navigation);
}
.news__title{
    color: var(--white);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
}
.news__label{
    color: var(--black);
        font-size: var(--font_twelve);
        font-weight: var(--weight_five);
}
.news__typerHolder{
    background-color: var(--news_input);
}
.news__textarea{
    border: none;
    outline: none;
    background-color: transparent;
    max-width: 100%;
    resize: none;
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
}
.news__title{
    color: var(--black);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
}
.news__time {
    color: var(--timer);
    font-size: var(--lower_font);
    font-weight: var(--weight_five);
}
.news__divider{
    font-size: var(--font_twenty);
}
.news__optionBtns{
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--navigation);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
}
.news__scroller{
    overflow-y: auto;
}
.news,.placedStudents{
    box-shadow: 0px 0px 12px 2px lightgray;
    overflow: hidden;
    background-color: var(--white);
    height: 42vh;
        max-height: 42vh;
        min-height: 42vh;
}
/* end of news css */

/* placed  students css*/
.placedStds__title{
    color: var(--black);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
}
.placedStds__addBtn{
    font-size: var(--font_eighteen);
}
.placedStds__name{
    color: var(--black);
    font-size: var(--font_sixtee);
    font-weight: var(--weight_seven);
}
.placedStds__role {
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
}
.placedStds__img{
   min-width: 100%;
   height:1800px;
   min-height: 180px;
   max-height: 180px;
   object-fit: cover;
   object-position: center;
}
.placedStds__swiper.swiper{
    padding-bottom: 25px;
}
.placedStds__swiperHolder .swiper-button-prev,.placedStds__swiperHolder
.swiper-button-next{
    color: var(--white) !important;
}
.placedStds__swiperHolder .swiper-button-prev::after,
.placedStds__swiperHolder .swiper-button-next::after{
    font-size: var(--font_thirty);
}
/* end of placed students css */

/* reqeust css */
.req__hint{
    color: var(--black);
    font-size: var(--font_sixtee);
    font-weight: var(--weight_five);
}
.req__accordHeadImg{
    height: 50px;
    width: 50px;
    min-height: 50px;
    min-width: 50px;
    max-height: 50px;
    max-width: 50px;
    object-fit: cover;
}
.req__studentName{
    color: var(--black);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_six);
}
.req__accordHeadCont {
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
}
.req__accordHeadTime{
    color: var(--placeholder);
    font-size: var(--lower_font);
    font-weight: var(--weight_five);
}
.req__accordion button.accordion-button:not(.collapsed), .req__accordion .accordion-item:last-of-type .accordion-button.collapsed{
    background-color: var(--white);
    border-radius: 5px !important;
    box-shadow: none !important;
}
.req__accordion .accordion-header,.req__accordion .accordion-item{
    border-radius: 5px !important;
}

.req__accordion button::after{
    display: none;
}
.req_contDestination{
    color: var(--black);
    font-size: var(--font_sixtee);
    font-weight: var(--weight_six);
}
.req_contDestination span,.req__contChange span{
    font-size: var(--font_thirteen);
}
.req_contDestination span i{
    font-size: var(--font_twelve);
}
.req__contChange{
    color: var(--secondary_black);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
}
.req__slctAllLabel .form-check-input.is-valid,
.req__slctAllLabel .was-validated .form-check-input:valid{
    border: 2px solid var(--navigation);
    box-shadow: none !important;
}
.req__slctAllLabel .form-check-input.is-valid:checked,
.req__slctAllLabel .was-validated .form-check-input:valid:checked{
    background-color: var(--navigation);
}
.req__slctAllLabel .form-check-input.is-valid~.form-check-label,
.req__slctAllLabe .was-validated .form-check-input:valid~.form-check-label{
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
}
.req__rejectChecker .form-check-input.is-valid,
.req__rejectChecker .was-validated .form-check-input:valid {
    border: 2px solid var(--bootstrap_red);
    box-shadow: none !important;
}

.req__rejectChecker .form-check-input.is-valid:checked,
.req__rejectChecker .was-validated .form-check-input:valid:checked {
    background-color: transparent;
    background-image: url('../Images/close.png');
    background-position: center;
    background-size: 8px 8px;
    background-repeat: no-repeat;
}

.req__rejectChecker .form-check-input.is-valid~.form-check-label,
.req__rejectChecker .was-validated .form-check-input:valid~.form-check-label {
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
}
.req__acceptChecker .form-check-input.is-valid,
.req__acceptChecker .was-validated .form-check-input:valid {
    border: 2px solid var(--bootstrap_green);
    box-shadow: none !important;
}

.req__acceptChecker .form-check-input.is-valid:checked,
.req__acceptChecker .was-validated .form-check-input:valid:checked {
    background-color: transparent;
    background-image: url('../Images/approve.png');
    background-position: center;
    background-size: 9px 8px;
    background-repeat: no-repeat;
}

.req__acceptChecker .form-check-input.is-valid~.form-check-label,
.req__acceptChecker .was-validated .form-check-input:valid~.form-check-label {
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
}
.req__approveLabel{
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_six);
}

.req__acceptCheck .form-check-input.is-valid,
.req__acceptCheck .was-validated .form-check-input:valid {
    border: 2px solid var(--green);
    box-shadow: none !important;
}
.req__acceptCheck .form-check-input.is-valid:checked,
.req__acceptCheck .was-validated .form-check-input:valid:checked {
    background-color: var(--green);
}

.req__rejectCheck .form-check-input.is-valid,
.req__rejectCheck .was-validated .form-check-input:valid {
    border: 2px solid var(--red);
    box-shadow: none !important;
}

.req__rejectCheck .form-check-input.is-valid:checked,
.req__rejectCheck .was-validated .form-check-input:valid:checked {
    background-color: var(--red);
}
.req__checkSelection{
    gap: 70px;
}
.req__accordSingleReq{
    border-bottom: 1px solid var(--placeholder);
}
.req__filterBtn{
border: none;
outline: none;
background-color: var(--navigation);
color: var(--white);
font-size: var(--font_thirteen);

}
.req__accordBodyScroller{
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}
.req__rejectChecker{

}
/* end of request css */
/* addons css */
.addons__leftTitle{
    color: var(--black);
    font-size: var(--font_sixtee);
    font-weight: var(--weight_six);
}
.addons__slideHolder{
    box-shadow: 0px 0px 12px 2px lightgray;
}
.addons__slideLabel{
    color: var(--white);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
}
.addons__closer{
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--white);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
    position: absolute;
        right: 1%;
            top: 20%;
    z-index: 2;
}
.addons__closer:hover{
color: var(--black);
}
.addons__singleSlide{
    background-color: var(--navigation);
    border: 5px solid transparent;
}
.addons__singleSlide.active{
border-color: var(--slide_border);
}
.addons__slideScroller{
    height: 85%;
    min-height: 85%;
    max-height: 85%;
    overflow-y: auto;
}
.addons__bannerPreview{
    min-height: 220px;
    height: 220px;
    max-height: 220px;
    object-fit: cover;
    min-width: 100%;
}
.addons__bannerDesc{
    color: var(--white);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
    position: absolute;
    bottom: 10%;
    right: 5%;
}
.addons__bannerAdder{
    box-shadow: 0px 0px 12px 2px lightgray;
}
.addons__bannerAdderFileInpHolder{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 160px;
    min-height: 160px;
    max-height: 160px;
    background-color: var(--img_uploader);
}
.addons__imgUploaderBtn{
    border: none;
    outline: none;
    background-color: var(--black);
    color: var(--white);
    font-size: var(--font_sixtee);
    font-weight: var(--weight_seven);
    padding: 15px 20px;
}
.addons__imgUploaderHint{
    color: var(--secondary_black);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
}
.addons__bannerAdderFileInp{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    opacity: 0;
}
.addons__bannerHintTyper{
    border: 1px solid var(--input_border);
    outline: none;
    background-color: transparent;
    color: var(--secondary_black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}
.addons__stdtImgHolder{
    width: 100%;
    height: 250px;
   min-width: 100%;
       min-height: 250px;
       max-width: 100%;
           max-height: 250px;
           border: 2px dashed var(--input_border);
}
.addons__cmnInput{
    border: 1px solid var(--input_border);
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    width: 100%;
        min-width: 100%;
        max-width: 100%;
}
.addons__bannerEditor{
    border: none;
    outline: none;
    background-color: #fff;
    color: var(--black);
    font-size: var(--font_twelve);
    font-weight: var(--weight_five);
    padding: 3px 8px;
    position: absolute;
    right: 5%;
    top: 8%;
    z-index: 1;

}
.addons__bannerEditor:hover{
    background-color: var(--navigation);
    color: var(--white);
}
.addons__slideHolder{
    max-height: 81vh;
}
.addons__slidesPreview{
    max-height: 81vh;
    overflow-y: auto;
}
.addons__slidesPreview::-webkit-scrollbar{
    display: none;
}
/* end of addons css */

/* edit profile css */
.editProfile__formScroller{
max-height: 500px;
min-height: 500px;
overflow-y: auto;
background-color: var(--gray_bg);
box-shadow: 0px 0px 12px 2px lightgray;
width: 100%;
}
.editProfile__singleForm{
    min-height: 450px;
}
.editProfile__textarea{
    border: 1px solid var(--input_border);
    outline: none;
    color: var(--black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_four);
}
.single__thumb .range-slider__thumb[data-lower]{
display: none;
}
.single__thumb .range-slider__range{
left: 0 !important;
border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
height: 6px !important;
}
.single__thumb .range-slider__thumb{
height: 18px !important;
width: 18px !important;
}
.single__thumb.range-slider{
background: var(--slider_bg);
}
.editProfile__label{
color: var(--secondary_black);
font-size: var(--font_fifteen);
font-weight: var(--weight_five);
}
.editProfile__slideValue{
border: 2px solid var(--slider_bg);
padding: 5px;
width: fit-content;
min-width: 60px;
max-width: 100px;
}
.editProfile__slideValue p{
color: var(--black);
font-size: var(--font_thirteen);
font-weight: var(--weight_five);
}
.editProfile__slideValue span{
color: var(--secondary_black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    }
    .editProfile__stepper{
        position: sticky !important;
        top: 0;
    }
.editProfile__singleStep{
    border: 2px solid var(--black);
    outline: none;
    color: var(--black);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
    padding: 3px 10px;
    background-color: var(--gray_bg);
    position: relative;
}
.stepper__bgBorder{
border: 1px solid var(--black);
height: 350px;
position: absolute;
}
.active-scroll-spy {
    background-color: yellowgreen;
    border-radius: 15px;
}
/* end of edit profile css */

/* modal css */
.cmn__modalTitle{
color: var(--black);
font-size: var(--font_sixtee);
font-weight: var(--weight_six);
}
.req__daysFilter :is(ul,li){
list-style-type: none;
padding-left: 0;
}
.req__daysFilter li{
color: var(--black);
font-size: var(--font_twelve);
font-weight: var(--weight_five);
}
.req__daysFilter li:hover{
background-color: #a4d3ff;
color: var(--navigation);
border-radius: 5px;
}
.req__daysFilter .selectedDate{
    background-color: #a4d3ff;
    color: var(--navigation);
    border-radius: 5px;
}
.req__calendarHolder .react-calendar__tile--active{
background: var(--navigation) !important;
}
.req__calendarHolder .react-calendar button{
border-radius: 5px;
}
.req__calendarHolder .react-calendar__navigation__label__labelText{
    font-weight: var(--weight_five);
}
.req__calendarHolder abbr[title],abbr[title]{
text-decoration: none m !important;
}
/* .req__calendarHolder .react-calendar__tile:enabled:hover,
.req__calendarHolder .react-calendar__tile:enabled:focus{

} */
.addwork__imageHolder{
    height: 350px;
    min-height: 350px;
    max-height: 350px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    border: 2px dashed var(--input_border);
}

/* end of modal css */
/* end of karthick css */



/* Jayakumaar Css 13-5-24 & 14-5-24*/



.next__btn,
.done__btn {
    border: 1px solid var(--navigation);
    outline: none;
    background-color: var(--navigation);
    color: var(--white);
    font-size: var(--font_sixtee);
    font-weight: var(--weight_four);
    /* padding: 5px 40px; */
    width: 180px;
    height: 40px;
    border-radius: 5px;
}


.previous__btn {
    /* border: 1px solid var(--navigation); */
    outline: none;
    /* background-color: transparent; */
    color: var(--navigation);
    font-size: var(--font_sixtee);
    font-weight: var(--weight_five);
    /* padding: 5px 20px; */
    width: fit-content;
}

.previous__btn:hover {
    color: var(--navigation);
}


.addStudents__container {
    background-color: var(--gray_bg);
    /* width: fit-content; */
}

.addStudents__mainContainer {
    background-color: var(--gray_bg);
    /* width: fit-content; */
}

.addstudents-input label{
    color: var(--placeholder);
}

.addstudentDetails__gender option, 
.addstudentDetails-m-status option, 
.addstudentDetails-state option,
.addstudentDetails-city option,
.addstudentDetails-pincode option,
.addstudentDetails-course option,
.addstudentDetails-e-status option,
.addstudentDetails-degree option,
.addstudentDetails-source option
{
    background-color: var(--navigation);
    color: white;
}


/* Indicator start */
.addStudents__indicatorContainer .addStudents__indicator {
    height: 10px;
    width: 60px;
    border: 1px solid var(--navigation);
    margin: 10px;
    border-radius: 20px;
    transition-duration: 0.7s;
}

.addStudents__indicatorContainer .active {
    background-color: var(--navigation);
}



/* Indicator end */

/*End of Jayakumaar Css */


/* media query */
@media(width > 1200px){
.common__inner {
    overflow: hidden;
}
}
@media(width < 576px) {
    .header__label {
        font-size: var(--font_twelve);
    }
        .header__logo {
            height: 40px;
            width: 80px;
        }
}

/* end of media query */